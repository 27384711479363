import React from "react"

const ArrowXReverse = ({ location, title, children }) => {
  return (
    <span className="c-icon__arrow">
    <span className="c-icon__arrow__origin c-icon__arrow__origin__x-reverse">
        <svg className="c-icon__arrow__def"
             width="16"
             height="15"
             viewBox="0 0 16 15"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path className="c-icon__arrow-path"
                  d="M15 7.32507L1 7.32507"
                  stroke="#0C3B2E"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
            <path className="c-icon__arrow-path"
                  d="M7.04687 1.27913L1.00076 7.32524L7.04688 13.373"
                  stroke="#0C3B2E"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    </span>
    <span className="c-icon__arrow__dammy c-icon__arrow__dammy__x-reverse">
        <svg className="c-icon__arrow__def"
             width="16"
             height="15"
             viewBox="0 0 16 15"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path className="c-icon__arrow-path"
                  d="M15 7.32507L1 7.32507"
                  stroke="#0C3B2E"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
            <path className="c-icon__arrow-path"
                  d="M7.04687 1.27913L1.00076 7.32524L7.04688 13.373"
                  stroke="#0C3B2E"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    </span>
</span>  )
}

export default ArrowXReverse