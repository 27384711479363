import { Link } from "gatsby"
import React from "react"
import ArrowXReverse from "../../common/ArrowXReverse"

const Main = () => {
  return (
    <section className="p-newsletter-main">
      <div className="p-newsletter-main__wrapper">
        <div className="p-newsletter-main__content">
          <p className="c-txt__main p-newsletter-main__content-txt">
            CRISP NEWSLETTERに登録いただきありがとうございます。 <br />
            外食の未来をもっと面白く変えていくため、トライ＆エラーを繰り返し、私たちは日々進化しています。そんな私たちから届く新しい挑戦にワクワクしていてください。
          </p>
        </div>
        <Link
          to={"/"}
          className="js-hover p-newsletter-main__link"
          data-hover-in-time="600"
          data-hover-out-time="650"
          href="/"
        >
          <div className="c-link__main p-newsletter-main__link-wrapper">
            <span className="p-newsletter-main__link-span">
              <span className="c-link__main-inner">HOMEへ戻る</span>
              <ArrowXReverse />
            </span>
          </div>
        </Link>
      </div>
    </section>
  )
}

export default Main
