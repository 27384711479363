import React from "react"

const Kv = ({ title }) => {
  return (
    <section className="p-common-kv p-<?php echo $page ?>-kv <?php echo $under ?>">
      <div className="p-common-kv__wrapper">
        <div className="p-common-kv__head">
          <h1 className="p-common-kv__head-ttl">{title}</h1>
        </div>
      </div>
    </section>
  )
}

export default Kv
